// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { defineComponent } from 'vue'
import { mapActions, mapMutations } from 'vuex'
import { AlertType } from '@/_typings/enums/alertType'
import { BadgeType } from '@/_typings/enums/badgeType'
import { IAccount } from '@/_typings/interfaces/account'
import { IAddress } from '@/_typings/interfaces/address'
import { IContact } from '@/_typings/interfaces/contact'
import Badge from '@/components/controls/badge/index.vue'
import Checkbox from '@/components/controls/checkbox/index.vue'
import Dropdown from '@/components/controls/drop-down/index.vue'
import ListTable from '@/components/controls/list-table/index.vue'
import TextInput from '@/components/controls/text-input/index.vue'
import Toggle from '@/components/controls/toggle/index.vue'
import confirmationTypes from '@/components/modals/confirmation/confirmationTypes'
import getPageTitle from '@/utils/getPageTitle'
import showConfirmationModal from '@/utils/showConfirmationModal'
import { getIsActiveBadgeInfo } from '@/utils/getBadgeInfo'
import { getCountries, getStates } from 'country-state-picker'
import JQuery from 'jquery'

interface ICountry {
  id: string;
  code: string;
  dial_code: string;
  name: string;
}

interface IState {
  id: string;
  name: string;
}

export default defineComponent({
  name: 'account',
  components: {
    Badge,
    Checkbox,
    Dropdown,
    ListTable,
    TextInput,
    Toggle
  },
  BadgeType,
  getPageTitle,
  getIsActiveBadgeInfo,
  data() {
    return {
      account: {} as IAccount,
      address: {} as IAddress,
      contact: {} as IContact,
      countries: [] as ICountry[],
      isSaving: false,
      readonly: false,
      states: [] as IState[],
      validatorKey: {
        account: 0,
        address: 0,
        contact: 0
      }
    }
  },
  created() {
    if (!!this.$route.params.accountId) { // view / edit
      this.fetchAccount({ accountId: this.$route.params.accountId }).then(data => {
        this.account = data
      })
      this.readonly = true
    } else { // new
      this.account = { isActive : true } as IAccount
    }
    const countries = getCountries().map(country => ({ ...country, id: country.code }))
    const usIndex = countries.findIndex(({ code }) => code === 'us')
    this.countries = [ countries[usIndex], ...countries.filter(({ code }) => code !== 'us') ]
  },
  updated() {
    JQuery(function () {
      JQuery('[data-toggle="tooltip"]').tooltip({ placement: 'top', customClass: 'tooltip', trigger: 'hover', delay: { show: 800, hide: 100 } })
    })
  },
  computed: {
    defaultAccountAddresses() : IAddress[] {
      return this.account.accountAddresses ? this.account.accountAddresses.items.filter(item => item.isDefaultBilling || item.isDefaultShipping) : []
    },
    primaryAccountContacts() : IContact[] {
      return this.account.contacts ? this.account.contacts.items.filter(item => item.isPrimary) : []
    }
  },
  methods: {
    ...mapMutations([
      'setAlert',
      'setBreadcrumbText'
    ]),
    ...mapActions([
      'addAccount',
      'addAccountAddress',
      'addAccountContact',
      'deleteAccountAddress',
      'deleteAccountContact',
      'fetchAccount',
      'fetchAccountAddress',
      'fetchAccountAddresses',
      'fetchAccountContact',
      'fetchAccountContacts',
      'updateAccount',
      'updateAccountAddress',
      'updateAccountContact',
      'updateAccountDefaultBillingAddress',
      'updateAccountDefaultShippingAddress',
      'updateAccountPrimaryContact'
    ]),
    changeAddressCountry(country) {
      this.address.country = country.code
      this.address.state = null
      this.states = getStates(country.code).map(state => ({ id: state, name: state }))
    },
    deleteAcctAddress(address) {
      showConfirmationModal(confirmationTypes.DELETE, () => {
        this.deleteAccountAddress({ accountId: this.account.id, addressId: address.addressId }).then((data) => {
          this.getPagedAccountAddresses(this.account.accountAddresses.paging)
        })
      })
    },
    getPagedAccountAddresses(pageInfo: any) {
      this.fetchAccountAddresses({ accountId: this.account.id, pageInfo: { ...this.account.accountAddresses.paging, ...pageInfo } }).then(data => {
        this.account.accountAddresses = data
      })
    },
    getPagedAccountContacts(pageInfo: any) {
      this.fetchAccountContacts({ accountId: this.account.id, pageInfo: { ...this.account.contacts.paging, ...pageInfo } }).then(data => {
        this.account.contacts = data
      })
    },
    saveAccount() {
      this.validatorKey.account++
      this.$nextTick(() => {
        if (this.$el.querySelectorAll('#account .form-input .invalid').length) return
        this.isSaving = true
        const action = this.account.id ? this.updateAccount : this.addAccount
        action({ account: this.account })
          .then((data) => {
            if (this.account.id) {
              this.account = data
              this.setBreadcrumbText({ id: this.account.id, text: this.account.name })
              this.readonly = true
            } else {
              this.$router.push({ path: '/refresh', query: { name: 'account', id: data.id } })
            }
            this.setAlert({ type: AlertType.Success, title: this.$t('reseller_account-save-1_message'), message: this.$t('reseller_account-save-2_message'), timeout: 3000 })
          })
          .finally(() => {
            this.isSaving = false
          })
      })
    },
    saveAccountAddress() {
      this.validatorKey.address++
      this.$nextTick(() => {
        if (this.$el.querySelectorAll('#addresses .form-input .invalid').length) return
        const action = this.address.id ? this.updateAccountAddress : this.addAccountAddress
        action({ accountId: this.account.id, address: this.address }).then((data) => {
          this.getPagedAccountAddresses(this.account.accountAddresses.paging)
          JQuery('#address').modal('hide')
        })
      })
    },
    saveAccountContact() {
      this.validatorKey.contact++
      this.$nextTick(() => {
        if (this.$el.querySelectorAll('#contacts .form-input .invalid').length) return
        const action = this.contact.id ? this.updateAccountContact : this.addAccountContact
        action({ accountId: this.account.id, contact: this.contact }).then((data) => {
          this.getPagedAccountContacts(this.account.contacts.paging)
          JQuery('#contact').modal('hide')
        })
      })
    },
    saveDefaultBillingAddress(address) {
      this.updateAccountDefaultBillingAddress({ accountId: this.account.id, addressId: address.addressId, value: !address.isDefaultBilling }).then((data) => {
        this.account = data
      })
    },
    saveDefaultShippingAddress(address) {
      this.updateAccountDefaultShippingAddress({ accountId: this.account.id, addressId: address.addressId, value: !address.isDefaultShipping }).then((data) => {
        this.account = data
      })      
    },
    savePrimaryAccountContact(contact) {
      this.updateAccountPrimaryContact({ accountId: this.account.id, contactId: contact.id, value: !contact.isPrimary }).then((data) => {
        this.account = data
      })
    },
    async showAccountAddressModal(address) {
      this.address = (address && address.addressId) ? await this.fetchAccountAddress({ accountId: this.account.id, addressId: address.addressId }) : { country: 'us' } as IAddress
      this.states = (this.address.country) ? getStates(this.address.country).map(state => ({ id: state, name: state })) : []
      this.validatorKey.address = 0
      JQuery('#address').modal()
    },
    async showAccountContactModal(contact) {
      this.contact = (contact && contact.id) ? await this.fetchAccountContact({ accountId: this.account.id, contactId: contact.id }) : { isActive: true } as IContact
      this.validatorKey.contact = 0
      JQuery('#contact').modal()
    }
  }
})
