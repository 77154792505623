// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { IColumn } from '@/_typings/interfaces/column'
import { IPagedList } from '@/_typings/interfaces/paged-list'
import GridTable from '@/components/controls/grid-table/index.vue'
import confirmationTypes from '@/components/modals/confirmation/confirmationTypes'
import showConfirmationModal from '@/utils/showConfirmationModal'

export default defineComponent({
  name: 'accounts',
  components: {
    GridTable
  },
  data() {
    return {
      pagedList: {} as IPagedList,
      selectedItems: [] as any[]
    }
  },
  created() {
    this.getPagedItems({ sortedBy: 'name', sortDirection: 'Ascending' })
  },
  computed: {
    columns() : IColumn[] {
      return [
        { id: 'name', dataType: 'String', label: this.$t('account_name_label'), name: 'name', isHyperlink: true },
        { id: 'companyPhone', dataType: 'String', label: this.$t('account_phone_label'), name: 'companyPhone'},
        { id: 'companyEmail', dataType: 'String', label: this.$t('account_email_label'), name: 'companyEmail'},
        { id: 'website', dataType: 'String', label: this.$t('account_website_label'), name: 'website'}
      ] as IColumn[]
    }
  },
  methods: {
    ...mapActions([
      'deleteAccounts',
      'deleteAccount',
      'fetchAccounts'
    ]),
    deleteItem({ id }) {
      showConfirmationModal(confirmationTypes.DELETE, () => {
        this.deleteAccount({ accountId: id }).then(() => {
          this.getPagedItems({})
          this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== id)
        })
      })
    },
    deleteItems() {
      showConfirmationModal(confirmationTypes.DELETE_MULTIPLE, () => {
        this.deleteAccounts({ accountIds: this.selectedItems.map(selectedItem => selectedItem.id) }).then(() => {
          this.getPagedItems({})
          this.selectedItems = []
        })
      })
    },
    getPagedItems(pageInfo: any) {
      this.fetchAccounts({ pageInfo: { ...this.pagedList.paging, ...pageInfo } }).then(data => {
        this.pagedList = data
      })
    },
    navigateToAccount(id) {
      this.$router.push({ name: id ? 'account' : 'account-new', params: { accountId: id ? id : null } })
    }
  }
})
