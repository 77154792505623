<!-- (c) Cincom Systems, Inc. <2018> - <2022> -->
<!-- ALL RIGHTS RESERVED                      -->
<template>
  <div class="list-table">
    <div v-if="title || isSearchable" class="list-header">
      <div v-if="title" class="list-title">{{ title }}</div>
      <div v-if="isSearchable || isItemAddable" class="list-actions">
        <search-input v-if="isSearchable" :text="pageInfo.search" @search="searchItems($event)" @close="searchItems(null)"/>
        <button v-if="isItemAddable" class="btn" :disabled="!isEnabled" @click="$emit('add-item')">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>
    <div v-if="items.length === 0 && (Boolean(pageInfo.search) || hasNoDataNotification)" class="no-data">
      <i class="fal fa-file-alt"></i>
      <div>{{ Boolean(pageInfo.search) ? $t('no-records-found_label') : $t('no-data-available_label') }}</div>
    </div>
    <template v-else>
      <div class="list">
        <div v-for="(item, index) in items" :key="index" class="list-item">
          <slot name="list-item" :item="item"/>
        </div>
      </div>
      <pager :pageInfo="pageInfo" @change-page-index="changePageIndex($event)" @change-page-size="changePageSize($event)"/>
    </template>
  </div>
</template>
<script src="./list-table.ts"></script>
<style src="./list-table.scss" lang="scss"></style>
