// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent, PropType } from 'vue'
import { BadgeType } from '@/_typings/enums/badgeType'
import Pager from '@/components/controls/pager/index.vue'
import SearchInput from '@/components/controls/search-input/index.vue'
import { IPageInfo } from '@/_typings/interfaces/paging'

export default defineComponent({
  name: 'list-table',
  components: {
    Pager,
    SearchInput
  },
  BadgeType,
  props: {
    hasNoDataNotification: { type: Boolean, default: false },
    isEnabled: { type: Boolean, default: true },
    isItemAddable: { type: Boolean, default: false },
    isSearchable: { type: Boolean, default: false },
    items: { type: Array as PropType<any[]>, default: () => [] },
    pageInfo: { type: Object as PropType<IPageInfo>, default: () => { return {} } },
    title: { type: String, default: null }
  },
  emits: [ 'add-item', 'change-paged-items', 'change-selected-items' ],
  methods: {
    changePageIndex(pageIndex: number) {
      this.$emit('change-paged-items', { ...this.pageInfo, pageNumber: pageIndex })
    },
    changePageSize(pageSize: number) {
      this.$emit('change-paged-items', { ...this.pageInfo, pageNumber: 1, pageSize: pageSize })
    },
    searchItems(searchText: string) {
      this.$emit('change-paged-items', { ...this.pageInfo, pageNumber: 1, search: searchText })
    }
  }
})
